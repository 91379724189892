@import './styles/animations.css';
@import './styles/tailwindcss-custom-forms.css';
@import './styles/tenant-themes.css';

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

input:not([type='radio']):not([type='checkbox']) {
  -webkit-appearance: none;
}

@tailwind base;
@tailwind components;

*,
:before,
:after {
  box-sizing: border-box;
  border: 0 solid #d2d6dc;
}

body {
  @apply bg-cool-gray-200 font-sans;
}

.block-scroll {
  height: 100%;
  overflow: hidden;
}

.btn {
  @apply py-4 px-6 bg-cool-gray-500 rounded-lg text-white m-2 cursor-pointer transition ease-in-out duration-150;
  touch-action: manipulation;
}

.btn:focus {
  @apply outline-none shadow-outline;
}
.can-hover .btn:hover,
.can-hover .btn:focus {
  @apply bg-cool-gray-600;
}

.can-hover .btn:active,
.can-hover .btn.btn-subtle:active,
.can-hover .btn.btn-blue:active,
.can-hover .btn.btn-magic:active {
  @apply shadow-outline;
}

.btn.btn-disabled {
  @apply opacity-25 select-none cursor-not-allowed;
}
.btn.btn-disabled.btn-magic {
  @apply opacity-50;
}
.can-hover .btn.btn-disabled:active {
  @apply outline-none;
}
.can-hover .btn.btn-disabled:hover,
.can-hover .btn.btn-disabled:focus {
  @apply bg-cool-gray-400;
}

.btn-blue,
.can-hover .btn-blue.btn-disabled:hover,
.can-hover .btn-blue.btn-disabled:focus {
  @apply bg-blue-600 border-2 border-blue-600 border-solid;
}
.can-hover .btn-blue:hover,
.can-hover .btn-blue:focus {
  @apply bg-blue-800 border-2 border-blue-800 border-solid;
}

.btn-subtle,
.can-hover .btn-subtle.btn-disabled:hover {
  @apply bg-transparent border-cool-gray-300 border-solid border-2 text-cool-gray-600;
}

.can-hover .btn-subtle:hover,
.can-hover .btn-subtle:focus {
  @apply bg-cool-gray-100 shadow;
}

.btn-blue.btn-subtle {
  @apply bg-transparent border-blue-400 text-blue-500;
}
.can-hover .btn-blue.btn-subtle:hover,
.can-hover .btn-blue.btn-subtle:focus {
  @apply bg-cool-gray-100;
}

.btn-magic {
  @apply text-white border-none transition-all duration-100 ease-in-out;
  background-image: linear-gradient(135deg, #58b1ff, #2b6fd4);
}
.can-hover .btn-magic:hover:not(.btn-disabled),
.can-hover .btn-magic:focus:not(.btn-disabled) {
  background-image: linear-gradient(135deg, #5fa5e2, #106ffb);
}

.btn-text {
  @apply bg-transparent py-2 px-4 self-center font-medium text-gray-800;
}
.can-hover .btn-text:hover,
.can-hover .btn-text:focus {
  @apply text-gray-600 bg-transparent;
}

.select-btn {
  @apply px-4 py-3 m-2 border-cool-gray-300 border-solid border-2 rounded-lg cursor-pointer text-center flex items-center transition ease-in-out duration-150;
}

.can-hover .select-btn:not(.accented):hover,
.can-hover .select-btn:not(.accented):focus {
  @apply shadow bg-cool-gray-100;
}
.can-hover .select-btn:focus,
.can-hover .select-btn:not(.accented):focus,
.can-hover .select-btn:active,
.can-hover .select-btn:not(.accented):active {
  @apply shadow-outline outline-none;
}

.textbox {
  @apply p-2 px-4 border-cool-gray-300 border-solid border-2 rounded-lg w-full;
  transition: 0.2s all ease;
}
.textbox:focus,
.textbox:focus-within {
  @apply outline-none shadow-outline;
}
.textbox::placeholder {
  @apply text-cool-gray-500;
}

.Select-placeholder {
  @apply text-cool-gray-500 !important;
}

.Select-placeholder,
.Select-input,
.Select-value {
  @apply px-4 !important;
}

.Select-menu .Select-option {
  text-transform: capitalize;
}

.error-message {
  @apply text-red-700 mt-2 text-sm;
  animation-name: fadein;
  animation-duration: 0.4s;
}

.Select-control > :last-child {
  padding-right: 8px !important;
}

.Select-value-label {
  text-transform: capitalize;
}

@tailwind utilities;
