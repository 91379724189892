/* Tesla Tenant */

body.tenant-tesla {
  @apply bg-black;
}
.tenant-tesla .btn,
.tenant-tesla .btn.btn-magic {
  @apply transition-colors duration-100 ease-in-out text-white uppercase font-medium !important;
  border: 1px solid #fff !important;
  background: transparent !important;
}
.tenant-tesla .btn:hover {
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(102, 102, 102, 0.4) 0%,
    rgba(196, 196, 196, 0) 100%
  ) !important;
}
.tenant-tesla .btn.btn-blue,
.tenant-tesla .btn.btn-magic {
  background: linear-gradient(
    180deg,
    rgba(131, 131, 131, 0.6) 0%,
    rgba(80, 80, 80, 0) 100%
  ) !important;
}
.tenant-tesla .btn.btn-blue:hover,
.tenant-tesla .btn.btn-magic:hover {
  background: linear-gradient(
      180deg,
      rgba(131, 131, 131, 0.6) 0%,
      rgba(80, 80, 80, 0) 100%
    )
    radial-gradient(
      50% 50% at 50% 50%,
      rgba(102, 102, 102, 0.4) 0%,
      rgba(196, 196, 196, 0) 100%
    ) !important;
}
.tenant-tesla .Base {
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(102, 102, 102, 0.4) 0%,
    rgba(196, 196, 196, 0) 100%
  );
}
.tenant-tesla .Nav {
  @apply bg-black;
}
.tenant-tesla .Nav .btn {
  border: none !important;
}

.tenant-tesla .text-black,
.tenant-tesla .text-gray-700,
.tenant-tesla .text-cool-gray-600,
.tenant-tesla .text-cool-gray-700,
.tenant-tesla .text-cool-gray-800,
.tenant-tesla .text-cool-gray-900 {
  @apply text-gray-50;
}
.tenant-tesla .text-cool-gray-500 {
  @apply text-gray-200;
}
.tenant-tesla .bg-cool-gray-300 {
  @apply bg-gray-800;
}
.tenant-tesla .bg-cool-gray-600 {
  @apply bg-gray-200;
}
.tenant-tesla .bg-cool-gray-100,
.tenant-tesla .hover\:.bg-cool-gray-100:hover {
  @apply bg-black;
}
.tenant-tesla .select-btn {
  @apply text-gray-200 bg-transparent border border-gray-200 !important;
}
.tenant-tesla .Modal {
  @apply bg-black !important;
}
.tenant-tesla .bg-blue-100,
.tenant-tesla .hover\:bg-blue-100:hover,
.tenant-tesla .hover\:bg-cool-gray-100:hover {
  background-color: #5f5f5f;
}
.tenant-tesla .border-blue-300 {
  border-color: #5f5f5f;
}
.tenant-tesla .text-blue-600 {
  color: white;
}
.tenant-tesla .border-blue-600 {
  border-color: white;
}
.tenant-tesla .border-cool-gray-600,
.tenant-tesla .border-cool-gray-400 {
  border-color: white;
}
.tenant-tesla {
  font-family: 'Gotham A', 'Gotham B';
  font-style: normal;
  font-weight: 500;
}
.tenant-tesla .ManagePolicy {
  color: #fff;
}
.tenant-tesla .ManagePolicy .bg-white {
  background-color: #313131;
}
.tenant-tesla .ManagePolicy .bg-gray-50 {
  background-color: #4c4c4c;
}
.tenant-tesla .ManagePolicy .text-gray-900,
.tenant-tesla .ManagePolicy .text-gray-800,
.tenant-tesla .ManagePolicy .text-gray-700,
.tenant-tesla .ManagePolicy .text-gray-600,
.tenant-tesla .ManagePolicy .text-gray-500 {
  color: #fff;
}
.tenant-tesla .ManagePolicy .text-indigo-600 {
  color: #bebbf9;
}
.tenant-tesla .ManagePolicy .bg-blue-600 {
  background-color: #5e5e5e;
}
.tenant-tesla .ManagePolicy .bg-blue-400 {
  background-color: #5e5e5e;
}
.tenant-tesla .bg-blue-300,
.tenant-tesla .bg-blue-500 {
  background-color: #1b1b1b;
}
.tenant-tesla .text-blue-500 {
  color: #fff;
}
.tenant-tesla .form-checkbox {
  @apply bg-gray-400;
}
.tenant-tesla .bg-green-100 {
  @apply bg-black border-white text-white border;
}
.tenant-tesla .text-green-600 {
  @apply text-white;
}
@screen sm {
  .tenant-tesla .ManagePolicy .sm\:bg-cool-gray-50 {
    background-color: #4e4e4e;
  }
}

/* USAA Tenant */

.tenant-usaa .text-cool-gray-600,
.tenant-usaa .text-cool-gray-700,
.tenant-usaa .textbox,
.tenant-usaa .Select-multi-value-wrapper,
.tenant-usaa .Select-value,
.tenant-usaa .Select-value-label,
.tenant-usaa .Select-input,
.tenant-usaa .Dropdown,
.tenant-usaa .form-checkbox {
  color: #003a63 !important;
}
.tenant-usaa .btn-blue {
  background-color: #003a63;
  border: none;
}
.tenant-usaa .btn-blue:hover,
.tenant-usaa .btn-blue:focus {
  background-color: #00668a;
  border: none;
}
.tenant-usaa .btn-magic {
  background: linear-gradient(
    180deg,
    rgba(0, 102, 138, 1.5) 0%,
    rgba(18, 57, 91, 0.8) 100%
  ) !important;
}

/* Mercury Tenant */

.tenant-mercury .btn-blue {
  background-color: #991b1e;
  border: none;
}
.tenant-mercury.can-hover .btn-blue:hover,
.tenant-mercury.can-hover .btn-blue:focus,
.tenant-mercury.can-hover .btn-blue.btn-disabled:hover {
  background-color: #77080a;
  border: none;
}
.tenant-mercury .btn-magic {
  background: linear-gradient(90deg, #941a1b 0%, #77080a 100%) !important;
}

/* Progressive Tenant */

.tenant-progressive {
  --color-primary: #0077b3;
  --color-primary-active: #2862a4;
  --color-primary-muted: #b6def2;
}
.tenant-progressive .btn-blue {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}
.tenant-progressive.can-hover .btn-blue:hover,
.tenant-progressive.can-hover .btn-blue.btn-disabled:hover,
.tenant-progressive.can-hover .btn-blue:focus,
.tenant-progressive.can-hover .btn-blue.btn-disabled:focus {
  background-color: var(--color-primary-active);
  border-color: var(--color-primary-active);
}
.tenant-progressive .btn-magic {
  background: var(--color-primary) !important;
}
.tenant-progressive.can-hover .btn-magic:hover,
.tenant-progressive.can-hover .btn-magic:focus {
  background: var(--color-primary-active) !important;
}
.tenant-progressive .bg-blue-500,
.tenant-progressive .bg-blue-600 {
  background-color: var(--color-primary);
}
.tenant-progressive .border-blue-600 {
  border-color: var(--color-primary);
}
.tenant-progressive .text-blue-500,
.tenant-progressive .text-blue-600 {
  color: var(--color-primary);
}
.tenant-progressive .bg-blue-300 {
  background-color: var(--color-primary-muted);
}

/* HiRoad Tenant */

.tenant-hiroad {
  --color-primary: #95d600;
  --color-primary-active: #8cc900;
  --color-text-on-primary: #233746;
  --color-gray-600: #41424c;
}
.tenant-hiroad .btn-blue {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  color: var(--color-text-on-primary);
  font-weight: 500;
}
.tenant-hiroad.can-hover .btn-blue:hover,
.tenant-hiroad.can-hover .btn-blue:focus,
.tenant-hiroad.can-hover .btn-blue.btn-disabled:hover {
  background-color: var(--color-primary-active);
  border-color: var(--color-primary-active);
}
.tenant-hiroad .btn-magic {
  background: var(--color-primary) !important;
  color: var(--color-text-on-primary);
  font-weight: 500;
}
.tenant-hiroad.can-hover .btn-magic:hover {
  background: var(--color-primary-active) !important;
}
.tenant-hiroad .bg-blue-600 {
  background-color: var(--color-primary);
  color: var(--color-text-on-primary);
}
.tenant-hiroad .border-blue-600 {
  border-color: var(--color-primary);
}
/* For "outlined" style buttons */
.tenant-hiroad .btn.border-blue-600.text-blue-600 {
  color: var(--color-gray-600);
}

/* State Farm Tenant */

.tenant-statefarm {
  --color-primary: #d62311;
  --color-primary-active: #a11b13;
  --color-primary-active-background: #f4f3f3;
  --color-primary-muted: #e4e3e3;
}
.tenant-statefarm {
  font-family: 'MecherleSans', Arial;
  font-style: normal;
  font-weight: 500;
}
body.tenant-statefarm {
  background-color: #fff;
}
.tenant-statefarm .text-cool-gray-800 {
  @apply text-gray-800;
}
.tenant-statefarm .text-cool-gray-700 {
  @apply text-gray-700;
}
.tenant-statefarm .text-cool-gray-600 {
  @apply text-gray-600;
}
.tenant-statefarm .text-cool-gray-500 {
  @apply text-gray-500;
}
.tenant-statefarm .text-cool-gray-400 {
  @apply text-gray-400;
}
.tenant-statefarm .text-cool-gray-300 {
  @apply text-gray-300;
}
.tenant-statefarm .bg-cool-gray-800 {
  @apply bg-gray-800;
}
.tenant-statefarm .bg-cool-gray-700 {
  @apply bg-gray-700;
}
.tenant-statefarm .bg-cool-gray-600 {
  @apply bg-gray-600;
}
.tenant-statefarm .bg-cool-gray-500 {
  @apply bg-gray-500;
}
.tenant-statefarm .bg-cool-gray-400 {
  @apply bg-gray-400;
}
.tenant-statefarm .bg-cool-gray-300 {
  @apply bg-gray-300;
}
.tenant-statefarm h1,
.tenant-statefarm h2,
.tenant-statefarm h3,
.tenant-statefarm h4 {
  font-weight: 500 !important;
  line-height: inherit !important;
}
.tenant-statefarm .text-md {
  @apply text-base !important;
}
.tenant-statefarm .Base {
  box-shadow: none !important;
  border: 1px solid #e4e3e3;
}
.tenant-statefarm .Nav {
  box-shadow: none !important;
  background-color: #fff;
  border: none !important;
  border-bottom: 1px solid #e4e3e3 !important;
}
.tenant-statefarm .btn {
  background-color: #fff;
  color: #403c3c;
  border-radius: 28px !important;
  font-weight: 600 !important;
  border: 1px solid #e4e3e3 !important;
}
.tenant-statefarm.can-hover .btn:hover,
.tenant-statefarm.can-hover .btn:focus {
  background-color: #e4e3e3;
}
.tenant-statefarm .btn-blue {
  color: #fff;
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}
.tenant-statefarm.can-hover .btn-blue:hover,
.tenant-statefarm.can-hover .btn-blue.btn-disabled:hover,
.tenant-statefarm.can-hover .btn-blue:focus,
.tenant-statefarm.can-hover .btn-blue.btn-disabled:focus {
  background-color: var(--color-primary-active);
  border-color: var(--color-primary-active);
}
.tenant-statefarm .btn-magic {
  color: #fff;
  background: var(--color-primary) !important;
}
.tenant-statefarm.can-hover .btn-magic:hover,
.tenant-statefarm.can-hover .btn-magic:focus {
  background: var(--color-primary-active) !important;
}
.tenant-statefarm .bg-blue-500,
.tenant-statefarm .bg-blue-600 {
  background-color: var(--color-primary);
}
.tenant-statefarm .border-blue-400,
.tenant-statefarm .border-blue-500,
.tenant-statefarm .border-blue-600 {
  border-color: var(--color-primary);
}
.tenant-statefarm .text-blue-500,
.tenant-statefarm .text-blue-600,
.tenant-statefarm .text-blue-700 {
  color: var(--color-primary);
}
.tenant-statefarm .hover\:text-blue-700:hover {
  color: var(--color-primary-active);
}
.tenant-statefarm .bg-blue-300 {
  background-color: var(--color-primary-muted);
}
.tenant-statefarm .border-blue-300,
.tenant-statefarm .focus\:border-blue-300:focus {
  border-color: var(--color-primary);
}
.tenant-statefarm .bg-blue-100 {
  background-color: var(--color-primary-active-background);
}
.tenant-statefarm .select-btn {
  @apply border-gray-300;
}
.tenant-statefarm.can-hover .select-btn:not(.accented):hover,
.tenant-statefarm.can-hover .select-btn:not(.accented):focus {
  @apply bg-gray-100;
}
.tenant-statefarm .textbox {
  @apply border-gray-300;
}
.tenant-statefarm .textbox::placeholder {
  @apply text-gray-500;
}
.tenant-statefarm .Select-placeholder {
  @apply text-gray-500 !important;
}
.tenant-statefarm .Select-control {
  @apply border-gray-300 !important;
}
.tenant-statefarm .shadow-outline-blue,
.tenant-statefarm .focus\:shadow-outline-blue:focus {
  @apply outline-none shadow-gray-200;
}
.tenant-statefarm .btn:focus,
.tenant-statefarm.can-hover .btn:active,
.tenant-statefarm.can-hover .btn.btn-subtle:active,
.tenant-statefarm.can-hover .btn.btn-blue:active,
.tenant-statefarm.can-hover .btn.btn-magic:active,
.tenant-statefarm .textbox:focus,
.tenant-statefarm .textbox:focus-within,
.tenant-statefarm.can-hover .select-btn:focus,
.tenant-statefarm.can-hover .select-btn:not(.accented):focus,
.tenant-statefarm.can-hover .select-btn:active,
.tenant-statefarm.can-hover .select-btn:not(.accented):active {
  @apply outline-none shadow-gray-200;
}
.tenant-statefarm footer.text-cool-gray-600 {
  @apply text-gray-400;
}
.tenant-statefarm .ManagePolicy.Base {
  border: none !important;
}
.tenant-statefarm .ManagePolicy .shadow-md {
  @apply shadow-none !important;
  border: 1px solid #e4e3e3;
}
