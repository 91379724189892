.Shake {
  animation-name: shake;
  animation-duration: 0.4s;
  animation-fill-mode: both;
}
@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  50%,
  90% {
    transform: translateX(-10px);
  }
  30%,
  70% {
    transform: translateX(10px);
  }
}

@keyframes slidein {
  from {
    margin-top: -50%;
  }

  to {
    margin-top: 0%;
  }
}

@keyframes slideout {
  from {
    margin-top: 0%;
  }

  to {
    margin-top: -50%;
  }
}

@keyframes slideup_soft {
  from {
    opacity: 0;
    margin-top: 2%;
  }

  to {
    opacity: 1;
    margin-top: 0%;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadein_delay {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeout {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
